<!--
    @name: testQuesPage
    @description：testQuesPage
    @author: ZengWei
    @date: 2022-04-01 13:59
-->
<template>
  <div v-loading="loading" style="height: 100%;overflow-y: auto">
    <QuesParser
      v-if="paperData"
      :paper-data="paperData"
      :form-data="formData"
      :paper-log="paperLog">
    </QuesParser>
  </div>
</template>

<script>
import QuesParser from "@/custom-component/form/quesParser/QuesParser";
import {formRequest} from "@/apis/home";

export default {
  name: "testQuesPage",
  components: {QuesParser},
  props: {},
  data() {
    return {
      loading:true,
      paperId: this.$route.query.paperId,
      formData: null,
      paperData: null,
      paperLog: {},
    }
  },
  created() {
    this.judgeUserPaper()
  },
  methods: {
    judgeUserPaper(){
      const data = {
        __method_name__: 'dataList',
        object_uuid: 'object624800db1cbc3',
        view_uuid: 'view625d1b5e288bd',
        transcode: 0,
        paper_id: this.$route.query.paperId
      }
      formRequest('POST', '/api/mapi', data).then(res => {
        if (res.data.code === 200) {
          const userLog = res.data.data?.[0]
          if(userLog){
            this.paperLog = userLog.answer
          }
          this.getDetailPaper()
        }
      })
    },
    getDetailPaper(){
      const data = {
        __method_name__: 'dataInfo',
        object_uuid: 'object6248009c7f1ed',
        data_id: this.paperId,
        transcode: 0
      }
      formRequest('POST', '/api/mapi', data).then(res => {
        if (res.data.code === 200) {
          this.formData = res.data.data.config
          const {name,title,desc,time_length} = res.data.data
          this.paperData = {name,title,desc,time_length}
        }
        this.loading = false;
      })
    },
  },
}
</script>

<style scoped>

</style>
